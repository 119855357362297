<div class="flex-row mb-5 space-between w-100">
  <div class="flex-row gap-4">
    <div>
      <mat-icon aria-hidden="true" svgIcon="award"></mat-icon>
    </div>
    <div>
      <label class="mb-0">Screen Share</label>
    </div>
  </div>
  <mat-slide-toggle
    name="toggleScreenSharing"
    [ngModel]="model.screenSharingEnabled"
    (ngModelChange)="screenSharingChange($event)" />
</div>

<div class="flex-col gap-4 w-100">
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon" aria-hidden="true" svgIcon="user"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100">
        <div class="flex-col">
          <label for="standardModeToggle" class="font-weight-bold">Standard Mode</label>
          <span class="u_block text-muted info-text">Allow one person to share by entering the Pairing Code.</span>
        </div>
        <mat-slide-toggle
          [disabled]="!model.screenSharingEnabled"
          [(ngModel)]="model.screenSharingStandard"
          (change)="screenShareModeChange('standard', $event)"
          name="screenSharingStandard"
          id="standardModeToggle"></mat-slide-toggle>
      </div>
      <div class="flex-row space-between w-100">
        <div class="row-entry space-between align-start">
          <label
            class="font-weight-normal"
            for="requirePinConfirmationToggle">
            Require Pin Confirmation
          </label>
          <mat-slide-toggle
            [(ngModel)]="model.screenSharingPinRequired"
            [disabled]="!model.screenSharingEnabled || !model.screenSharingStandard"
            name="requirePinConfirmationToggle"
            #screenSharingEnabled="ngModel"
            ngDefaultControl
            id="requirePinConfirmationToggle"></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-row gap-3 align-start">
    <div>
      <mat-icon class="svg-icon" aria-hidden="true" svgIcon="users"></mat-icon>
    </div>
    <div class="row-entry flex-col align-start gap-4">
      <div class="flex-row space-between w-100">
        <div class="flex-col">
          <label
            for="moderatorModeToggle"
            class="font-weight-bold">
            Moderator Mode
          </label>
          <span class="u_block text-muted info-text">Allow multiple participants to join a session by entering the pairing code. They will not be able to join using Airplay.</span>
        </div>
        <mat-slide-toggle
          [disabled]="!model.screenSharingEnabled"
          (change)="screenShareModeChange('moderated', $event)"
          [(ngModel)]="model.screenSharingModerated"
          name="moderatorModeToggle"
          id="moderatorModeToggle"></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
